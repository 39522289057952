import React, { useEffect, useRef, useState } from 'react';
import { Grid, Typography, Box, Button, Menu, MenuItem, } from '@mui/material';
import Banner from '../../images/booking/image (8).png';
import Banner2 from '../../images/booking/image (9).png';
import Section2 from '../../images/booking/section2.jpg';
import Section3 from '../../images/booking/section3.jpg';
import Faq from '../../images/booking/faq.png';
import Terms from '../../../src/components/diwali/booking-t&c';
import { useNavigate } from 'react-router-dom';
import Travel1 from "../../images/booking/LOCKED/Fastrack Eyewear.png";
import Travel2 from "../../images/booking/LOCKED/Skybags.png";
import Travel3 from "../../images/booking/LOCKED/Woggles.png";
import Adventure1 from "../../images/booking/LOCKED/adidas.png";
import Adventure2 from "../../images/booking/LOCKED/campus.png";
import Adventure3 from "../../images/booking/LOCKED/ouma.png";
import Adventure4 from "../../images/booking/LOCKED/Onitsuka Tiger.png";
import Protein1 from "../../images/booking/LOCKED/true elements.png";
import Protein2 from "../../images/booking/LOCKED/max protein.png";
import Acwo from "../../images/booking/LOCKED/ACWO.png";
import Infire from "../../images/booking/LOCKED/infire.png";
import Noise from "../../images/booking/LOCKED/noise.png";
import Beauty1 from "../../images/booking/LOCKED/Biotique.png";
import Beauty2 from "../../images/booking/LOCKED/mCaffeine.png";
import Beauty3 from "../../images/booking/LOCKED/Ustraa.png";
import Beauty4 from "../../images/booking/LOCKED/Bombay Shaving Company.png";


import { Accordion, AccordionSummary, AccordionDetails, CardContent, Card, } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';



export default function Signup() {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedItem, setSelectedItem] = useState("Travel Accessories"); // Default menu item
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };


    const handleClose = (item) => {
        setSelectedItem(item); // Set selected item as button label
        setAnchorEl(null);
    };


    const navigate = useNavigate();
    const formRef = useRef(null);
    const formReff = useRef(null);

    const [expanded, setExpanded] = useState(true); // Initialize to `true` to keep it open by default

    const handleChange = (isExpanded) => {
        setExpanded(isExpanded);
    };

    // State variables for popups and API response

    // useEffect(() => {
    //     const ck_id = localStorage.getItem('booking_ck_id');
    //     if (ck_id) {
    //         setOpenRepeatPopup(true);
    //     }
    // }, []);

    useEffect(()=>{
        let bundle = localStorage.getItem('travel_ck_id')
        if(bundle){
            navigate('/booking/index',{replace:true});

        }
    },[])

    const handleSubmit = async () => {
        try {
            const params = new URLSearchParams(window.location.search);
            let ck_id = params.get('userid') || params.get('user_id') || params.get('userId') || params.get('ck_id') || params.get('ckid');

            let response = await fetch('https://leaderboard-1.earningshub.co/api/user/create_user_travel', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ck_id: ck_id }),
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }

            const data = await response.json();
            localStorage.setItem('travel_ck_id', ck_id)
            navigate('/booking/index',{replace:true});
        } catch (error) {
            const params = new URLSearchParams(window.location.search);
            let ck_id = params.get('userid') || params.get('user_id') || params.get('userId') || params.get('ck_id') || params.get('ckid');
            localStorage.setItem('travel_ck_id', ck_id)
            navigate('/booking/index');
            console.log("Error:", error.message);
        }
    };


    const scrollToElementt = () => {
        formReff.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    return (
        <Grid container sx={{ background: '#fff' }}>
            <Grid item xs={12} sm={12} md={4} lg={4} />

            <Grid item xs={12} sm={12} md={4} lg={4}>
                <img src={Banner} alt='Banner' style={{ width: '100%' }} />
                <Grid sx={{ position: 'absolute', marginTop: '-65%', left: '25%' }}>
                    <button style={{ background: 'blue', padding: '10px', border: 'none', borderRadius: '8px', color: '#fff', fontWeight: '700' }} onClick={scrollToElementt}>Explore The Benefits</button>
                </Grid>
                <img src={Banner2} alt='Banner2' style={{ width: '100%' }} ref={formReff} />
                <img src={Section2} alt='Section2' style={{ width: '100%' }} />
                <img src={Section3} alt='Section3' style={{ width: '100%' }} />
                <Grid item xs={12} px={2} mb={2} sx={{ marginTop: '-82%' }}>

                    <Button
                        onClick={handleClick}
                        sx={{
                            backgroundColor: '#0057FF',
                            width: '100%',
                            textTransform: 'capitalize',
                            color: 'white',
                            fontWeight: 'bold',
                            borderRadius: '16px',
                            padding: '10px 16px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            '&:hover': {
                                backgroundColor: '#0046CC'
                            }
                        }}
                        endIcon={<ExpandMoreIcon />}
                    >
                        {selectedItem}
                    </Button>
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={() => handleClose(selectedItem)}
                        PaperProps={{
                            style: {
                                borderRadius: '8px',
                                marginTop: '5px',
                                backgroundColor: '#f5f5f5',
                                width: '100%',
                            }
                        }}
                        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    >
                        <MenuItem onClick={() => handleClose('Travel Accessories')} sx={{ textTransform: 'capitalize' }}>Travel Accessories</MenuItem>
                        <MenuItem onClick={() => handleClose('Adventure Ready Apparel')} sx={{ textTransform: 'capitalize' }}>Adventure Ready Apparel</MenuItem>
                        <MenuItem onClick={() => handleClose('Fuel for the Journey')} sx={{ textTransform: 'capitalize' }}>Fuel for the Journey</MenuItem>
                        <MenuItem onClick={() => handleClose('Journey Tunes & Tech')} sx={{ textTransform: 'capitalize' }}>Journey Tunes & Tech</MenuItem>
                        <MenuItem onClick={() => handleClose('Travel Skincare Essentials')} sx={{ textTransform: 'capitalize' }}>Travel Skincare Essentials</MenuItem>
                    </Menu>

                    {/* Conditionally render the Travel Accessories section */}
                    {selectedItem === "Travel Accessories" && (
                        <Grid container spacing={2} direction="row" alignItems="center" justifyContent="center" pt={2}>
                            <Grid item xs={4}>
                                {/* <a href='https://cashkaro.com/stores/fastrack-bcec' style={{ textDecoration: 'none' }}> */}
                                <img src={Travel1} alt="Fastrack Eyewear" style={{ width: '100%' }} />
                                {/* </a> */}
                            </Grid>
                            <Grid item xs={4}>
                                {/* <a href='https://cashkaro.com/stores/skybags-coupons-bcec' style={{ textDecoration: 'none' }}> */}
                                <img src={Travel2} alt="Skybags" style={{ width: '100%' }} />
                                {/* </a> */}
                            </Grid>
                            <Grid item xs={4}>
                                {/* <a href='https://cashkaro.com/stores/woggles-coupons-bcec' style={{ textDecoration: 'none' }}> */}
                                <img src={Travel3} alt="Woggles" style={{ width: '100%' }} />
                                {/* </a> */}
                            </Grid>
                        </Grid>
                    )}

                    {selectedItem === "Adventure Ready Apparel" && (
                        <Grid container spacing={2} direction="row" alignItems="center" justifyContent="center" pt={2}>
                            <Grid item xs={4}>
                                {/* <a href='https://cashkaro.com/stores/adidas-bcec' style={{ textDecoration: 'none' }}> */}
                                <img src={Adventure1} alt="Adidas" style={{ width: '100%' }} />
                                {/* </a> */}
                            </Grid>
                            <Grid item xs={4}>
                                {/* <a href='https://cashkaro.com/stores/campus-shoes-coupons-bcec' style={{ textDecoration: 'none' }}> */}
                                <img src={Adventure2} alt="Campus Shoes" style={{ width: '100%' }} />
                                {/* </a> */}
                            </Grid>
                            <Grid item xs={4}>
                                {/* <a href='https://cashkaro.com/stores/puma-coupons-bcec' style={{ textDecoration: 'none' }}> */}
                                <img src={Adventure3} alt="Puma" style={{ width: '100%' }} />
                                {/* </a> */}
                            </Grid>
                            {/* <Grid item xs={4}>
            <a href='https://cashkaro.com/stores/onitsuka-tiger-coupons-bcec' style={{ textDecoration: 'none' }}>
                <img src={Adventure4} alt="Onitsuka Tiger" style={{ width: '100%' }} />
            </a>
        </Grid> */}
                        </Grid>
                    )}

                    {selectedItem === "Fuel for the Journey" && (
                        <Grid container spacing={2} direction="row" alignItems="center" justifyContent="center" pt={2}>
                            <Grid item xs={4}>
                                {/* <a href='https://cashkaro.com/stores/adidas-bcec' style={{ textDecoration: 'none' }}> */}
                                <img src={Protein1} alt="Adidas" style={{ width: '100%' }} />
                                {/* </a> */}
                            </Grid>
                            <Grid item xs={4}>
                                {/* <a href='https://cashkaro.com/stores/campus-shoes-coupons-bcec' style={{ textDecoration: 'none' }}> */}
                                <img src={Protein2} alt="Campus Shoes" style={{ width: '100%' }} />
                                {/* </a> */}
                            </Grid>
                            {/* <Grid item xs={4}>
            <a href='https://cashkaro.com/stores/onitsuka-tiger-coupons-bcec' style={{ textDecoration: 'none' }}>
                <img src={Adventure4} alt="Onitsuka Tiger" style={{ width: '100%' }} />
            </a>
        </Grid> */}
                        </Grid>
                    )}

                    {selectedItem === "Journey Tunes & Tech" && (
                        <Grid container spacing={2} direction="row" alignItems="center" justifyContent="center" pt={2}>
                            <Grid item xs={4}>
                                {/* <a href='https://cashkaro.com/stores/adidas-bcec' style={{ textDecoration: 'none' }}> */}
                                <img src={Acwo} alt="Adidas" style={{ width: '100%' }} />
                                {/* </a> */}
                            </Grid>
                            <Grid item xs={4}>
                                {/* <a href='https://cashkaro.com/stores/campus-shoes-coupons-bcec' style={{ textDecoration: 'none' }}> */}
                                <img src={Infire} alt="Campus Shoes" style={{ width: '100%' }} />
                                {/* </a> */}
                            </Grid>
                            <Grid item xs={4}>
                                {/* <a href='https://cashkaro.com/stores/onitsuka-tiger-coupons-bcec' style={{ textDecoration: 'none' }}> */}
                                <img src={Noise} alt="Onitsuka Tiger" style={{ width: '100%' }} />
                                {/* </a> */}
                            </Grid>
                        </Grid>
                    )}
                    {selectedItem === "Travel Skincare Essentials" && (
                        <Grid container spacing={2} direction="row" alignItems="center" justifyContent="center" pt={2}>
                            <Grid item xs={4}>
                                {/* <a href='https://cashkaro.com/stores/adidas-bcec' style={{ textDecoration: 'none' }}> */}
                                <img src={Beauty1} alt="Adidas" style={{ width: '100%' }} />
                                {/* </a> */}
                            </Grid>
                            <Grid item xs={4}>
                                {/* <a href='https://cashkaro.com/stores/campus-shoes-coupons-bcec' style={{ textDecoration: 'none' }}> */}
                                <img src={Beauty2} alt="Campus Shoes" style={{ width: '100%' }} />
                                {/* </a> */}
                            </Grid>
                            <Grid item xs={4}>
                                {/* <a href='https://cashkaro.com/stores/onitsuka-tiger-coupons-bcec' style={{ textDecoration: 'none' }}> */}
                                <img src={Beauty3} alt="Onitsuka Tiger" style={{ width: '100%' }} />
                                {/* </a> */}
                            </Grid>
                        </Grid>
                    )}
                </Grid>




                <Grid item xs={12} ref={formRef}>
                    <Box px={5} pt={5} pb={4}>
                        <img src={Faq} alt='faq' style={{ width: '100%' }} />
                    </Box>
                    <Terms dynamicCss={{ color: "#000" }} />
                </Grid>
            </Grid>

            <Grid container sx={{ position: 'relative', background: 'transparent', }}pt={10}>
                <Grid item xs={12} sm={12} md={4} lg={4}
                    sx={{ position: 'fixed', bottom: 0, width: '100%' }}
                >
                    <Grid item xs={12} sx={{ background: 'blue', padding: '1rem', borderTopLeftRadius: '16px', borderTopRightRadius: '16px', marginTop: '20%' }} >
                        <Typography sx={{ textAlign: 'center', color: '#fff', fontWeight: '700', cursor: 'pointer' }} onClick={handleSubmit}>Join The Club Now</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} />

            {/* Add button to submit */}
            {/* <Grid item xs={12} display="flex" justifyContent="center" mt={2}>
                <Button variant="contained" color="primary" onClick={handleSubmit}>Submit</Button>
            </Grid> */}
        </Grid>
    );
}
