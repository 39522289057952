import React, { useEffect, useRef, useState } from 'react';
import { Grid, Typography, Box, Button, Menu, MenuItem } from '@mui/material';
import Banner from '../../images/booking/indexsection1.jpg';
import Card1 from '../../images/booking/card 3.png';
import Card2 from '../../images/booking/card 1.png';
import Card3 from '../../images/booking/card 2.png';
import Podium from '../../images/booking/stone.png';
import section2Locked from '../../images/booking/locked-coupon.png'
import Section3 from '../../images/booking/hesding.jpg';
import Faq from '../../images/booking/faq.png';
import Terms from '../../../src/components/diwali/booking-t&c';
import Travel1 from "../../images/booking/Fastrack Eyewear.png";
import Travel2 from "../../images/booking/Skybags.png";
import Travel3 from "../../images/booking/Woggles.png";
import Travel1Lock from "../../images/booking/LOCKED/Fastrack Eyewear.png";
import Travel2Lock from "../../images/booking/LOCKED/Skybags.png";
import Travel3Lock from "../../images/booking/LOCKED/Woggles.png";
import Adventure1 from "../../images/booking/adidas.png";
import Adventure2 from "../../images/booking/Campus.png";
import Adventure3 from "../../images/booking/Puma.png";
import Adventure4 from "../../images/booking/Onitsuka Tiger.png";
import Adventure1Lock from "../../images/booking/LOCKED/adidas.png";
import Adventure2Lock from "../../images/booking/LOCKED/campus.png";
import Adventure3Lock from "../../images/booking/LOCKED/ouma.png";
import Adventure4Lock from "../../images/booking/LOCKED/Onitsuka Tiger.png";
import Slide from "../../images/booking/slide.jpg";
import Fuel1 from "../../images/booking/Max Protein.png";
import Fuel2 from "../../images/booking/True Elements.png";
import Fuel1Lock from "../../images/booking/LOCKED/max protein.png";
import Fuel2Lock from "../../images/booking/LOCKED/true elements.png";
import Tech1 from "../../images/booking/ACWO.png";
import Tech2 from "../../images/booking/Boat.png";
import Tech3 from "../../images/booking/InFire.png";
import Tech4 from "../../images/booking/noise.png";
import Tech1Lock from "../../images/booking/LOCKED/ACWO.png";
import Tech2Lock from "../../images/booking/LOCKED/boat.png";
import Tech3Lock from "../../images/booking/LOCKED/infire.png";
import Tech4Lock from "../../images/booking/LOCKED/noise.png";
import Beauty1 from "../../images/booking/Biotique.png";
import Beauty2 from "../../images/booking/Bombay Shaving Company.png";
import Beauty3 from "../../images/booking/Ustraa.png";
import Beauty4 from "../../images/booking/mCaffeine.png";
import Beauty1Lock from "../../images/booking/LOCKED/Biotique.png";
import Beauty2Lock from "../../images/booking/LOCKED/Bombay Shaving Company.png";
import Beauty3Lock from "../../images/booking/LOCKED/Ustraa.png";
import Beauty4Lock from "../../images/booking/LOCKED/mCaffeine.png";




import { useNavigate } from 'react-router-dom';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    CardContent,
    Card,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LockIcon from '@mui/icons-material/Lock';


const nthNumber = (number) => {
    if (number > 3 && number < 21) return "th";
    switch (number % 10) {
        case 1:
            return number + "st";
        case 2:
            return number + "nd";
        case 3:
            return number + "rd";
        default:
            return number + "th";
    }
};

export default function Signup() {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedItem, setSelectedItem] = useState("Travel Accessories"); // Default menu item
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };


    const handleClose = (item) => {
        setSelectedItem(item); // Set selected item as button label
        setAnchorEl(null);
    };

    const navigate = useNavigate();
    const formRef = useRef(null);
    const [userData, setUserData] = useState({ txns: 0 })

    // State variables for popups and API response
    const [openRepeatPopup, setOpenRepeatPopup] = useState(false);

    const fetchUserData = async () => {
        try {
            let ck_id = localStorage.getItem('travel_ck_id')
            let response = await fetch(`https://leaderboard-1.earningshub.co/api/user/users_travel/${ck_id}`)
            response = await response.json()
            // console.log(response)
            setUserData(response)
        } catch (error) {
            // console.log(error.message)
            navigate('/back')
        }
    }

    useEffect(() => {
        fetchUserData()
    }, [])

    const scrollToElement = () => {
        formRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    return (
        <Grid container sx={{ background: '#fff' }}>
            <Grid item xs={12} sm={12} md={4} lg={4} />

            <Grid item xs={12} sm={12} md={4} lg={4}>
                <img src={Banner} alt='Banner' style={{ width: '100%' }} />
                <Grid container px={1}>
                    {userData?.txns < userData.target_1 ? '' : (
                        <>
                            <Grid item xs={4}>
                                <Typography
                                    sx={{
                                        textAlign: 'center',
                                        fontSize: '14px',
                                        fontWeight: '600',
                                        marginTop: '10%',
                                        position: 'absolute',
                                        left: '4%',
                                    }}
                                >
                                    Unlock after
                                    <br />
                                    {
                                        userData?.txns >= userData?.target_3
                                            ? `${nthNumber(userData?.target_2)} `
                                            : userData?.txns >= userData?.target_2
                                                ? `${nthNumber(userData?.target_1)} `
                                                : `${nthNumber(userData?.target_2)} `
                                    }
                                    booking
                                </Typography>
                            </Grid>

                            <Grid item xs={4}>
                                <Typography
                                    sx={{
                                        textAlign: 'center',
                                        fontSize: '14px',
                                        fontWeight: '600',
                                        paddingLeft: '16px',
                                        marginTop: '6%',
                                        position: 'absolute',
                                    }}
                                >
                                    Unlock after
                                    <br />
                                    {
                                        userData?.txns >= userData?.target_3
                                            ? `${nthNumber(userData?.target_3)} `
                                            : userData?.txns >= userData?.target_2
                                                ? `${nthNumber(userData?.target_2)} `
                                                : `${nthNumber(userData?.target_1)} `
                                    }
                                    booking
                                </Typography>
                            </Grid>

                            <Grid item xs={4}>
                                <Typography
                                    sx={{
                                        textAlign: 'center',
                                        fontSize: '14px',
                                        paddingLeft: '6%',
                                        fontWeight: '600',
                                        marginTop: '10%',
                                        position: 'absolute',
                                    }}
                                >
                                    Unlock after
                                    <br />
                                    {
                                        userData?.txns >= userData?.target_3
                                            ? `${nthNumber(userData?.target_1)} `
                                            : userData?.txns >= userData?.target_2
                                                ? `${nthNumber(userData?.target_3)} `
                                                : `${nthNumber(userData?.target_3)} `
                                    }
                                    booking
                                </Typography>
                            </Grid>
                        </>
                    )}


                </Grid>
                {/* Conditional rendering of Section2 or section2Locked based on transaction */}

                {userData?.txns >= userData?.target_3 ? <img src={Card3} alt='Section2' style={{ width: '100%', }} /> :

                    userData?.txns >= userData?.target_2 ? <img src={Card2} alt='Section2' style={{ width: '100%', }} /> :

                        userData?.txns >= userData?.target_1 ? <img src={Card1} alt='Section2' style={{ width: '100%', }} /> :
<                     a href='https://cashkaro.com/stores/booking' style={{textDecoration:'none'}}>
                            <img src={section2Locked} alt='section2Locked' style={{ width: '100%' }} />
                            </a>
                }

                {userData?.txns > 0 ? <>
                    <img src={Podium} alt='Section2' style={{ width: '100%', marginTop: '-16%' }} />
                    <div style={{paddingLeft:'1rem',paddingRight:'1rem'}}> 
                        <a href='https://cashkaro.com/stores/booking' style={{textDecoration:'none'}}>
                        <Button sx={{ width: '100%', color: '#fff',fontWeight:'600', background: 'blue',padding:'14px',borderRadius:'16px' }} >Book Now on Booking.com</Button>
                        </a>
                    </div>
                </> : ''}



                <img src={Section3} alt='Section3' style={{ width: '100%', paddingLeft: '16px', paddingRight: '16px' }} />
                <Grid item xs={12} px={2} mb={2} sx={{ marginTop: '0%' }}>

                    <Button
                        onClick={handleClick}
                        sx={{
                            backgroundColor: '#0057FF',
                            width: '100%',
                            textTransform: 'capitalize',
                            color: 'white',
                            fontWeight: 'bold',
                            borderRadius: '16px',
                            padding: '10px 16px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: '1rem',
                            marginTop: '2rem',
                            '&:hover': {
                                backgroundColor: '#0046CC'
                            }
                        }}
                        endIcon={<ExpandMoreIcon />}
                    >
                        {selectedItem}
                    </Button>
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={() => handleClose(selectedItem)}
                        PaperProps={{
                            style: {
                                borderRadius: '8px',
                                marginTop: '5px',
                                backgroundColor: '#f5f5f5',
                                width: '100%',

                            }
                        }}
                        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    >
                        <MenuItem onClick={() => handleClose('Travel Accessories')} sx={{ textTransform: 'capitalize' }}>Travel Accessories</MenuItem>
                        <MenuItem onClick={() => handleClose('Adventure Ready Apparel')} sx={{ textTransform: 'capitalize' }}>Adventure Ready Apparel</MenuItem>
                        <MenuItem onClick={() => handleClose('Fuel for the Journey')} sx={{ textTransform: 'capitalize' }}>Fuel for the Journey</MenuItem>
                        <MenuItem onClick={() => handleClose('Journey Tunes & Tech')} sx={{ textTransform: 'capitalize' }}>Journey Tunes & Tech</MenuItem>
                        <MenuItem onClick={() => handleClose('Travel Skincare Essentials')} sx={{ textTransform: 'capitalize' }}>Travel Skincare Essentials</MenuItem>
                    </Menu>

                    {/* Conditionally render the Travel Accessories section */}
                    {selectedItem === "Travel Accessories" && userData?.txns < 1 && (
                        <Grid container spacing={2} direction="row" alignItems="center" justifyContent="center">
                            <Grid item xs={6}>
                                {/* <a href='https://cashkaro.com/stores/fastrack-bcec' style={{ textDecoration: 'none' }}> */}
                                <img src={Travel1Lock} alt="Booking.com Malaysia" style={{ width: '100%' }} />
                                {/* </a> */}
                            </Grid>
                            <Grid item xs={6}>
                                {/* <a href='https://cashkaro.com/stores/skybags-coupons-bcec' style={{ textDecoration: 'none' }}> */}
                                    <img src={Travel2Lock} alt="Booking.com Malaysia" style={{ width: '100%' }} />
                                {/* </a> */}
                            </Grid>
                            <Grid item xs={6}>
                                {/* <a href='https://cashkaro.com/stores/woggles-coupons-bcec' style={{ textDecoration: 'none' }}> */}
                                    <img src={Travel3Lock} alt="Booking.com Malaysia" style={{ width: '100%' }} />
                                {/* </a> */}
                            </Grid>
                            <Grid item xs={6}>
                            </Grid>
                        </Grid>
                    )}
                    
{selectedItem === "Travel Accessories" && userData?.txns > 0 && (
                        <Grid container spacing={2} direction="row" alignItems="center" justifyContent="center">
                            <Grid item xs={6}>
                                <a href='https://cashkaro.com/stores/fastrack-bcec' style={{ textDecoration: 'none' }}><img src={Travel1} alt="Booking.com Malaysia" style={{ width: '100%' }} /></a>
                            </Grid>
                            <Grid item xs={6}>
                                <a href='https://cashkaro.com/stores/skybags-coupons-bcec' style={{ textDecoration: 'none' }}>
                                    <img src={Travel2} alt="Booking.com Malaysia" style={{ width: '100%' }} />
                                </a>
                            </Grid>
                            <Grid item xs={6}>
                                <a href='https://cashkaro.com/stores/woggles-coupons-bcec' style={{ textDecoration: 'none' }}>
                                    <img src={Travel3} alt="Booking.com Malaysia" style={{ width: '100%' }} />
                                </a>
                            </Grid>
                            <Grid item xs={6}>
                            </Grid>
                        </Grid>
                    )}

{selectedItem === "Adventure Ready Apparel" && userData?.txns < 1 &&(
                        <Grid container spacing={2} direction="row" alignItems="center" justifyContent="center">
                            <Grid item xs={6}>
                                {/* <a href='https://cashkaro.com/stores/adidas-bcec' style={{ textDecoration: 'none' }}> */}
                                    <img src={Adventure1Lock} alt="Booking.com Malaysia" style={{ width: '100%' }} />
                                {/* </a> */}
                            </Grid>
                            <Grid item xs={6}>
                                {/* <a href='https://cashkaro.com/stores/campus-shoes-coupons-bcec' style={{ textDecoration: 'none' }}> */}
                                    <img src={Adventure2Lock} alt="Booking.com Malaysia" style={{ width: '100%' }} />
                                {/* </a> */}
                            </Grid>
                            <Grid item xs={6}>
                                {/* <a href='https://cashkaro.com/stores/puma-coupons-bcec' style={{ textDecoration: 'none' }}> */}
                                    <img src={Adventure3Lock} alt="Booking.com Malaysia" style={{ width: '100%' }} />
                                {/* </a> */}
                            </Grid>
                            <Grid item xs={6}>
                                {/* <a href='https://cashkaro.com/stores/onitsuka-tiger-coupons-bcec' style={{ textDecoration: 'none' }}> */}
                                    <img src={Adventure4Lock} alt="Booking.com Malaysia" style={{ width: '100%' }} />
                                {/* </a> */}
                            </Grid>
                        </Grid>
                    )}
 {selectedItem === "Adventure Ready Apparel" && userData?.txns > 0 &&(
                        <Grid container spacing={2} direction="row" alignItems="center" justifyContent="center">
                            <Grid item xs={6}>
                                <a href='https://cashkaro.com/stores/adidas-bcec' style={{ textDecoration: 'none' }}>
                                    <img src={Adventure1} alt="Booking.com Malaysia" style={{ width: '100%' }} />
                                </a>
                            </Grid>
                            <Grid item xs={6}>
                                <a href='https://cashkaro.com/stores/campus-shoes-coupons-bcec' style={{ textDecoration: 'none' }}>
                                    <img src={Adventure2} alt="Booking.com Malaysia" style={{ width: '100%' }} />
                                </a>
                            </Grid>
                            <Grid item xs={6}>
                                <a href='https://cashkaro.com/stores/puma-coupons-bcec' style={{ textDecoration: 'none' }}>
                                    <img src={Adventure3} alt="Booking.com Malaysia" style={{ width: '100%' }} />
                                </a>
                            </Grid>
                            <Grid item xs={6}>
                                <a href='https://cashkaro.com/stores/onitsuka-tiger-coupons-bcec' style={{ textDecoration: 'none' }}>
                                    <img src={Adventure4} alt="Booking.com Malaysia" style={{ width: '100%' }} />
                                </a>
                            </Grid>
                        </Grid>
                    )}

 {selectedItem === "Fuel for the Journey" && userData?.txns < 1 &&(
                        <Grid container spacing={2} direction="row" alignItems="center" justifyContent="center">
                            <Grid item xs={6}>
                                {/* <a href='https://cashkaro.com/stores/max-protein-coupons-bcec' style={{ textDecoration: 'none' }}> */}
                                    <img src={Fuel1Lock} alt="fuel1" style={{ width: '100%' }} />
                                {/* </a> */}
                            </Grid>
                            <Grid item xs={6}>
                                {/* <a href='https://cashkaro.com/stores/true-elements-coupons-bcec' style={{ textDecoration: 'none' }}> */}
                                    <img src={Fuel2Lock} alt="fuel2" style={{ width: '100%' }} />
                                {/* </a> */}
                            </Grid>

                        </Grid>
                    )}


{selectedItem === "Fuel for the Journey" && userData?.txns > 0 &&(
                        <Grid container spacing={2} direction="row" alignItems="center" justifyContent="center">
                            <Grid item xs={6}>
                                <a href='https://cashkaro.com/stores/max-protein-coupons-bcec' style={{ textDecoration: 'none' }}>
                                    <img src={Fuel1} alt="fuel1" style={{ width: '100%' }} />
                                </a>
                            </Grid>
                            <Grid item xs={6}>
                                <a href='https://cashkaro.com/stores/true-elements-coupons-bcec' style={{ textDecoration: 'none' }}>
                                    <img src={Fuel2} alt="fuel2" style={{ width: '100%' }} />
                                </a>
                            </Grid>

                        </Grid>
                    )}

{selectedItem === "Journey Tunes & Tech" && userData?.txns < 1 &&(
                        <Grid container spacing={2} direction="row" alignItems="center" justifyContent="center">
                            <Grid item xs={6}>
                                {/* <a href='https://cashkaro.com/stores/acwo-coupons-bcec' style={{ textDecoration: 'none' }}> */}
                                    <img src={Tech1Lock} alt="Tech1" style={{ width: '100%' }} />
                                {/* </a> */}
                            </Grid>
                            <Grid item xs={6}>
                                {/* <a href='https://cashkaro.com/stores/boat-coupon-bcec' style={{ textDecoration: 'none' }}> */}
                                    <img src={Tech2Lock} alt="Tech2" style={{ width: '100%' }} />
                                {/* </a> */}
                            </Grid>
                            <Grid item xs={6}>
                                {/* <a href='https://cashkaro.com/stores/infire-coupons-bcec' style={{ textDecoration: 'none' }}> */}
                                    <img src={Tech3Lock} alt="Tech3" style={{ width: '100%' }} />
                                {/* </a> */}
                            </Grid>
                            <Grid item xs={6}>
                                {/* <a href='https://cashkaro.com/stores/gonoise-coupons-bcec' style={{ textDecoration: 'none' }}> */}
                                    <img src={Tech4Lock} alt="Tech4" style={{ width: '100%' }} />
                                {/* </a> */}
                            </Grid>

                        </Grid>

                    )}
  {selectedItem === "Journey Tunes & Tech" && userData?.txns > 0 &&(
                        <Grid container spacing={2} direction="row" alignItems="center" justifyContent="center">
                            <Grid item xs={6}>
                                <a href='https://cashkaro.com/stores/acwo-coupons-bcec' style={{ textDecoration: 'none' }}>
                                    <img src={Tech1} alt="Tech1" style={{ width: '100%' }} />
                                </a>
                            </Grid>
                            <Grid item xs={6}>
                                <a href='https://cashkaro.com/stores/boat-coupon-bcec' style={{ textDecoration: 'none' }}>
                                    <img src={Tech2} alt="Tech2" style={{ width: '100%' }} />
                                </a>
                            </Grid>
                            <Grid item xs={6}>
                                <a href='https://cashkaro.com/stores/infire-coupons-bcec' style={{ textDecoration: 'none' }}>
                                    <img src={Tech3} alt="Tech3" style={{ width: '100%' }} />
                                </a>
                            </Grid>
                            <Grid item xs={6}>
                                <a href='https://cashkaro.com/stores/gonoise-coupons-bcec' style={{ textDecoration: 'none' }}>
                                    <img src={Tech4} alt="Tech4" style={{ width: '100%' }} />
                                </a>
                            </Grid>

                        </Grid>

                    )}

                    {selectedItem === "Travel Skincare Essentials" && userData?.txns < 1 &&(
                        <Grid container spacing={2} direction="row" alignItems="center" justifyContent="center">
                            <Grid item xs={6}>
                                {/* <a href='https://cashkaro.com/stores/biotique-coupons-bcec' style={{ textDecoration: 'none' }}> */}
                                    <img src={Beauty1Lock} alt="Beauty1" style={{ width: '100%' }} />
                                {/* </a> */}
                            </Grid>
                            <Grid item xs={6}>
                                {/* <a href='https://cashkaro.com/stores/bombay-shaving-company-coupons-bcec' style={{ textDecoration: 'none' }}> */}
                                    <img src={Beauty2Lock} alt="Beauty2" style={{ width: '100%' }} />
                                {/* </a> */}
                            </Grid>
                            <Grid item xs={6}>
                                {/* <a href='https://cashkaro.com/stores/ustraa-coupons-bcec' style={{ textDecoration: 'none' }}> */}
                                    <img src={Beauty3Lock} alt="Beauty3" style={{ width: '100%' }} />
                                {/* </a> */}
                            </Grid>
                            <Grid item xs={6}>
                                {/* <a href='https://cashkaro.com/stores/mcaffeine-coupons-bcec' style={{ textDecoration: 'none' }}> */}
                                    <img src={Beauty4Lock} alt="Beauty4" style={{ width: '100%' }} />
                                {/* </a> */}
                            </Grid>

                        </Grid>

                    )}


{selectedItem === "Travel Skincare Essentials" && userData?.txns > 0 &&(
                        <Grid container spacing={2} direction="row" alignItems="center" justifyContent="center">
                            <Grid item xs={6}>
                                <a href='https://cashkaro.com/stores/biotique-coupons-bcec' style={{ textDecoration: 'none' }}>
                                    <img src={Beauty1} alt="Beauty1" style={{ width: '100%' }} />
                                </a>
                            </Grid>
                            <Grid item xs={6}>
                                <a href='https://cashkaro.com/stores/bombay-shaving-company-coupons-bcec' style={{ textDecoration: 'none' }}>
                                    <img src={Beauty2} alt="Beauty2" style={{ width: '100%' }} />
                                </a>
                            </Grid>
                            <Grid item xs={6}>
                                <a href='https://cashkaro.com/stores/ustraa-coupons-bcec' style={{ textDecoration: 'none' }}>
                                    <img src={Beauty3} alt="Beauty3" style={{ width: '100%' }} />
                                </a>
                            </Grid>
                            <Grid item xs={6}>
                                <a href='https://cashkaro.com/stores/mcaffeine-coupons-bcec' style={{ textDecoration: 'none' }}>
                                    <img src={Beauty4} alt="Beauty4" style={{ width: '100%' }} />
                                </a>
                            </Grid>

                        </Grid>

                    )}






                </Grid>
                {/* <Grid item xs={12} >
                    <img src={Slide} alt='slide' style={{ width: '100%' }} />
                </Grid> */}

                <Grid item xs={12} ref={formRef}>
                    <Box px={5} pt={3} pb={4}>
                        <img src={Faq} alt='Faq' width={'100%'} />
                    </Box>
                    <Terms />
                </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4} />

            {/* Add button to submit */}
            {/* <Grid item xs={12} display="flex" justifyContent="center" mt={2}>
                <Button variant="contained" color="primary" onClick={handleSubmit}>Submit</Button>
            </Grid> */}
        </Grid>
    );
}
